import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  changeFields(){
    axios.get(this.data.get('url')+'?ajax=true&profile_type_id='+this.element.value)
      .then((res) => {
        var d = document.createElement('div')
        d.innerHTML = res.data
        var form = d.getElementsByTagName('form')[0]
        this.element.closest('form').outerHTML = form.outerHTML
        d.remove()
      })
      .catch(function(e){
        console.log(e)
      })
  }
}
