/* eslint no-console:0 */
import styles from './styles/application.scss'

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import bsn from '../lib/bsn-custom'
window.bsn = bsn

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

