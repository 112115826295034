import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
  connect() {
    this.flatpicker = flatpickr(this.element, {
      dateFormat: 'm/d/Y',
      allowInput: true
    })
    this.element.setAttribute("autocomplete", "off")
  }

  disconnect() {
    this.flatpicker.destroy()
    this.element.removeAttribute("autocomplete")
  }
}
