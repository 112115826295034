export default class {
  constructor(type = 'currency') {
    this.type = type
  }

  format(number){
    if(!number) {return null}
    return this[this.type](number)
  }

  currency(number) {
    number = this.numberize(number)
    let formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 5 })
    return formatter.format(number)
  }

  integer(number) {
    number = this.numberize(number)
    let formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
    return formatter.format(number)
  }

  percent(number) {
    number = this.numberize(number)
    let formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 5 })
    return formatter.format(number)
  }

  decimal(number, options = {}) {
    number = this.numberize(number)
    let min = options['min'] || 2
    let max = options['max'] || 2
    let formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: min, maximumFractionDigits: max })
    return formatter.format(number)
  }

  numberize(input){
    return Number(String(input).replace(/,/g, ''))
  }
}
