import { Controller } from 'stimulus'
import axios from 'axios'
import Sortable from 'sortablejs'
import submitEvent from "../../../lib/submit-event"
import csrfHeader from "../../../lib/csrf-header"

export default class extends Controller {
  static targets = ['modalContainer', 'fieldList']

  connect() {
    this.sortableInstance = new Sortable(this.fieldListTarget, {
      handle: '.sort-handle',
      animation: 150
    })
  }

  disconnect() {
    this.sortableInstance.destroy()
  }

  showFields(event){
    event.preventDefault()
    axios.post("/exports/company_export_fields", this.formData, {headers: {'X-CSRF-Token': csrfHeader()}})
      .then((res) => {
        this.modalContainerTarget.innerHTML = res.data
        this.modalDiv = this.modalContainerTarget.children[0]

        this.modalDiv.addEventListener('hidden.bs.modal', () => {
          this.modalDiv.remove()
        })

        this.modalInstance = new bsn.Modal(this.modalDiv)
        this.modalInstance.show()
      })
  }

  get profileTypeId(){
    return document.getElementById('filters_profile_type_id').value
  }

  get formData(){
    let form = document.getElementById('company_export_form')
    let data = new FormData(form)
    data.append('_method', 'post')
    return data
  }

  get companyExportId(){
    let potentialId = document.getElementById('company_export_form').getAttribute("action").split("/").pop()
    return potentialId
  }

  checkboxToggle(event) {
    let [fieldKey, name] = event.target.getAttribute('name').split('--')

    console.log(fieldKey)
    console.log(name)

    if(event.target.checked){
      let child = document.createElement('li')
      child.classList.add('list-group-item')
      child.setAttribute('id', `field-list-${fieldKey}`)
      child.innerHTML = `
        <span class="fa fa-bars sort-handle"></span>
        ${name}
        <input type="hidden" value="${fieldKey}" name="company_export[selected_fields][]">
        <span class="fa fa-times remove-field" data-action="click->exports--fields#removeField"></span>
      `
      this.fieldListTarget.appendChild(child)
    }else{

      document.getElementById(`field-list-${fieldKey}`).remove()
    }
  }

  removeField(event) {
    event.currentTarget.parentNode.remove()
  }
}
