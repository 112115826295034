export default class {
  constructor(target, numberFormatter) {
    this.target = target
    this.numberFormatter = numberFormatter
  }

  setup(){
    this.target.addEventListener('keyup', this.handleKeyup.bind(this))
    this.target.addEventListener('blur', this.handleBlur.bind(this))
  }

  teardown(){
    this.target.removeEventListener('keyup', this.handleKeyup.bind(this))
    this.target.removeEventListener('blur', this.handleBlur.bind(this))
  }

  handleKeyup(event) {
    if(this.ignoreKey(event.which)) return

    var caret = this.target.selectionStart
    var origLength = this.target.value.length
    var origCommaCount = this.target.value.slice(0,caret).replace(/[^,]/g, '').length
    var result = this.formatNumber(this.target.value);
    var newCommaCount = result.slice(0,caret).replace(/[^,]/g, '').length
    this.target.value = result
    if (caret !== origLength && this.target === document.activeElement){
      this.target.selectionStart = caret + newCommaCount - origCommaCount
    }
  }

  formatNumber(number) {
    number += '';
    var commastripped = this.stripCommas(number);
    var numberParts = commastripped.split('.')

    var result = numberParts[0].replace(/[^\d]/g, '')
    var result = result.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

    if (numberParts.length > 1){
      result += '.';
      result += numberParts[1];
    }

    return result;
  }

  handleBlur(event) {
    this.target.value = this.numberFormatter.format(this.target.value)
  }

  stripCommas(string) {
    return string.replace(/,/g, '');
  }

  ignoreKey(key) {
    let codesToIgnore = [16, 9, 37, 38, 39, 40]
    if (codesToIgnore.indexOf(key) === -1){
      return false;
    }else{
      return true;
    }
  }
}
