import { Controller } from 'stimulus'
import NumberFormatter from '../../../lib/number-formatter'

export default class extends Controller {
  static targets = ["totalVolume", "totalTrans", "avgTicket", "volume", "transaction", "currentFees", "effectiveRate", "interchangeSection", "amexVolume", "volumeMarkup", "volumeMarkupFees", "pinDebitVolume", "transMarkup", "transMarkupFees", "amexTrans", "pinDebitTrans"]
  connect() {
    this.numberFormatter = new NumberFormatter
  }

  handleKeyup(event) {
    if(this.ignoreKey(event.which)) return;
    this.hidePricingOptions()
    this.updateCalculatedFields()
  }

  ignoreKey(key) {
    let codesToIgnore = [16, 9, 37, 38, 39, 40];
    if (codesToIgnore.indexOf(key) === -1){
      return false;
    }else{
      return true;
    }
  }

  hidePricingOptions() {
    if(this._hidden) return
    this._hidden = true
    document.getElementById('choose-pricing').classList.add('hidden')
    document.getElementById('save-pricing').classList.remove('hidden')
  }

  updateCalculatedFields() {
    let volume = this.updateVolume()
    let transactions = this.updateTransactions()
    let currentFees = this.currentFees
    let amexVolume = this.amexVolume
    let pinDebitVolume = this.pinDebitVolume
    let amexTrans = this.amexTrans
    let pinDebitTrans = this.pinDebitTrans

    this.updateAvgTicket(volume, transactions)
    this.updateEffectiveRate(volume, currentFees)
    this.updateVolumeMarkupFees(volume, amexVolume, pinDebitVolume)
    this.updateTransMarkupFees(transactions, amexTrans, pinDebitTrans)
  }

  updateVolume() {
    let totalVolume = 0
    for (let target of this.volumeTargets) {
      totalVolume += this.numberFormatter.numberize(target.value)
    }
    this.totalVolumeTarget.value = this.numberFormatter.currency(totalVolume)
    return totalVolume
  }

  updateTransactions() {
    let totalTransactions = 0
    for (let target of this.transactionTargets) {
      totalTransactions += this.numberFormatter.numberize(target.value)
    }
    this.totalTransTarget.value = this.numberFormatter.integer(totalTransactions)
    return totalTransactions
  }

  get currentFees() {
   return this.numberFormatter.numberize(this.currentFeesTarget.value)
  }

  get amexVolume() {
   return this.numberFormatter.numberize(this.amexVolumeTarget.value)
  }

  get pinDebitVolume() {
   return this.numberFormatter.numberize(this.pinDebitVolumeTarget.value)
  }

  get amexTrans() {
   return this.numberFormatter.numberize(this.amexTransTarget.value)
  }

  get pinDebitTrans() {
   return this.numberFormatter.numberize(this.pinDebitTransTarget.value)
  }

  updateAvgTicket(volume, transactions){
    var avgTicket = (volume / transactions)

    if (isFinite(avgTicket)){
      this.avgTicketTarget.value = this.numberFormatter.currency(avgTicket);
    }else{
      this.avgTicketTarget.value = '';
    }
    return avgTicket
  }

  updateEffectiveRate(volume, currentFees) {
    let currentRate = (currentFees / volume * 100).toFixed(3);
    this.effectiveRateTarget.value = currentRate
    return currentRate
  }

  updateVolumeMarkupFees(volume, amexVolume, pinDebitVolume){
    let markup = this.interchangeMarkup
    let fees = (markup/100) * (volume - amexVolume - pinDebitVolume);

    if (fees >= 0){
      this.volumeMarkupFeesTarget.value = this.numberFormatter.currency(fees)
    }else{
      this.volumeMarkupFeesTarget.value = ''
    }
  }

  updateTransMarkupFees(trans, amexTrans, pinDebitTrans){
    let markup = this.transMarkup
    let fees = markup * (trans - amexTrans - pinDebitTrans)

    if (fees >= 0){
      this.transMarkupFeesTarget.value = this.numberFormatter.currency(fees)
    }else{
      this.transMarkupFeesTarget.value = ''
    }
  }

  get interchangeMarkup() {
   return this.numberFormatter.numberize(this.volumeMarkupTarget.value)
  }

  get transMarkup() {
   return this.numberFormatter.numberize(this.transMarkupTarget.value)
  }

  changePricing(event) {
    let pricingType = event.currentTarget.value
    if(pricingType === 'interchange'){
      this.interchangeSectionTarget.classList.remove('hidden');
    }else{
      this.interchangeSectionTarget.classList.add('hidden');
    }
  }
}
