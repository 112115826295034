import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['cbHideGlobal', 'processor']

  connect() {
    this.updateProcessorVisibility()
  }

  updateProcessorVisibility(){
    if(this.cbHideGlobalTarget.checked) {
      this.showPanels()
    } else {
      this.hidePanels()
    }
  }

  hidePanels(){
    this.processorTargets.forEach(function(target){
      target.classList.add('hidden')
    })
  }

  showPanels(){
    this.processorTargets.forEach(function(target){
      target.classList.remove('hidden')
    })
  }
}
