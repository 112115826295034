import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  connect() {
    this.recentSeekIds = []
    this.pageUrl = window.location.href.split('?')[0];
    this.loader = document.getElementById('search-loader')

    window.addEventListener('scroll', this.infiniteScroll)
    this.infiniteScroll()
  }

  disconnect() {
    window.removeEventListener('scroll', this.infiniteScroll)
  }

  get prevPageInput(){
    return document.getElementById('prev-page')
  }

  get nextPageInput(){
    return document.getElementById('next-page')
  }

  docHeight(){
    // https://stackoverflow.com/questions/1145850/how-to-get-height-of-entire-document-with-javascript
    var body = document.body, html = document.documentElement

    return Math.max( body.scrollHeight, body.offsetHeight, 
                     html.clientHeight, html.scrollHeight, html.offsetHeight );
  }

  infiniteScroll = () => {
    if(this.docHeight() > window.innerHeight + window.pageYOffset + 60) { return; }

    let seekId = this.nextPageInput.value

    if(!seekId) return;
    if(this.recentSeekIds.indexOf(seekId) !== -1) return;

    this.recentSeekIds.unshift(seekId);

    if(this.recentSeekIds.length > 10) { this.recentSeekIds.pop() }

    this.loader.classList.remove('hidden');

    axios.get(this.pageUrl + '?seek_id=' + seekId)
      .then(function(resp){
        this.loader.classList.add('hidden');
        this.prevPageInput.remove()
        this.nextPageInput.remove()
        this.element.insertAdjacentHTML('beforeend', resp.data)
      }.bind(this))
  }
}
