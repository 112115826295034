import { Controller } from "stimulus"

export default class extends Controller {
  add(event) {
    let tempContainer = document.createElement(this.containerElement.tagName)

    tempContainer.innerHTML = this.newHtml
    this.containerElement.appendChild(tempContainer.firstChild)
    tempContainer.remove()

    event.preventDefault()
  }

  get newHtml() {
    return this.replaceIdPlacholders(this.htmlTemplate)
  }

  get htmlTemplate() {
    return this.addLinkElement.dataset.fields
  }

  replaceIdPlacholders(html) {
    return html.replace(/IDX/g, this.nextIndex)
  }

  get nextIndex() {
    return this.element.querySelectorAll('[data-controller="embedded-association"]').length
  }

  get addLinkElement() {
    return this.targets.find('addLink')
  }

  get containerElement() {
    return this.targets.find('container')
  }
}

