import { Controller } from 'stimulus'
import Dropzone from 'dropzone'
import axios from 'axios'
import Noty from '../../../lib/noty'
import csrfHeader from "../../../lib/csrf-header"

Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = ['index', 'dropzone']

  connect() {
    this.setUpFileUpload()
  }

  disconnect() {
    this.removeFileUpload()
  }

  setUpFileUpload() {
    let dropzoneUrl = this.data.get('url')
    let successUrl = this.data.get('success-url')

    this.dropzone = new Dropzone(this.dropzoneTarget, {
      url: dropzoneUrl,
      createImageThumbnails: false,
      headers: {
        'X-CSRF-Token': csrfHeader()
      },
      success: () => {
        axios({
          method: 'GET',
          url: successUrl,
        }).then((response) => {
          this.indexTarget.innerHTML = response.data
          new Noty({text: "Success"}).show()
        })
      },
      error: (response) => {
        let errors = JSON.parse(response.xhr.response).errors
        new Noty({type: 'error', text: errors}).show()
      },
      complete: (file) => {
        this.dropzone.removeFile(file)
      }
    })
  }

  removeFileUpload() {
    this.dropzone.destroy()
  }
}
