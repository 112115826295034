import Noty from 'noty'

Noty.overrideDefaults({
    layout   : 'topRight',
    theme    : 'relax',
    closeWith: ['click', 'button'],
    type: 'info',
    timeout: 3500
})

window.noty = function(opts) {
  new Noty(opts).show()
}

window.Noty = Noty

export default Noty
