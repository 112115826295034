import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  static targets = ["gateway"]

  focus(event) {
    this._prevProcessor = event.currentTarget.value
  }

  change(event) {
    let processorField = event.currentTarget
    let processorId = processorField.value
    this.gatewayTarget.setAttribute('disabled', 'disabled');

    axios.get('/gateways?processor_id=' + processorId)
      .then((resp) => {
        document.getElementById('gateway-select').outerHTML = resp.data
        this._prevProcessor = processorId
        this.gatewayTarget.removeAttribute('disabled')
      })
      .catch(function(e){
        console.log(e)
      })
  }
}
