import { Controller } from 'stimulus'

const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/

const BACKSPACE = 8,
	TAB = 9,
	ENTER = 13,
	DELETE = 46,
	DELETE2 = 8,
	COMMA = 188,
	COMMA2 = 44,
	COMMA3 = 60,
	SEMICOLON = 186,
	SEMICOLON2 = 59,
  SPACE = 32

const ADDKEYS = [TAB, ENTER, COMMA, COMMA2, SEMICOLON, SEMICOLON2, SPACE]
const DELETEKEYS = [DELETE, DELETE2]

export default class extends Controller {
  constructor(context) {
    super(context)
    this._fauxInput = null
    this._innerDiv = null
  }

  connect() {
    if(this.targets.find('fauxInput')) {
      this.targets.find('fauxInput').remove()
    }

    this.updateEmailList()
    this.originalInput.style.position = 'absolute'
    this.originalInput.style.height = '10px'
    this.originalInput.style.width = '10px'
    this.originalInput.style.zIndex = '-90'
    this.originalInput.setAttribute('tabindex', '-90')
    this.originalInput.parentNode.insertBefore(this.fauxInput, this.originalInput)
  }

  handleKeydown(event) {
    let key = this.getKey(event)

    if(ADDKEYS.includes(key) && this.innerDiv.innerText !== '') {
      event.preventDefault()
      this.addEmailToList()
    }
    else if(key === ENTER && this.innerDiv.innerText === '') {
      event.preventDefault()
    } else if(DELETEKEYS.includes(key)) {
      if(this.innerDiv.innerText !== '') { return }
      event.preventDefault()

      let lastOption = this.options[this.options.length-1]

      if (lastOption) {
        lastOption.remove()
        this.updateEmailList()
      }
    }
  }

  addEmailToList() {
    let trimmed = this.innerDiv.innerText.trim()

    if(trimmed.match(emailRegex)){
      this.addNewOption(trimmed)
      this.innerDiv.innerHTML = ''
      this.updateEmailList()
    } else {
      this.innerDiv.classList.add('invalid')
    }
  }

  get options() {
    return this.originalInput.querySelectorAll('option')
  }

  getKey(event) {
    return event.keyCode || event.which
  }

  updateEmailList() {
    let emailSpans = this.fauxInput.querySelectorAll('span')

    emailSpans.forEach(function(span){
      span.remove()
    })

    for (var i = this.options.length-1; i >= 0; i--) {
      let option = this.options[i]
      let span = document.createElement('span')
      span.innerHTML = option.value + `<span data-action="click->email-input#xremove" data-value="${option.value}" class="fa fa-times"></span>`
      this.fauxInput.prepend(span)
    }
  }

  xremove(event) {
    let target = event.target
    let value = target.dataset.value
    this.options.forEach(function(option){
      if(option.value === value) {
        option.remove()
      }
    })
    this.updateEmailList()
  }

  get fauxInput() {
    if(this._fauxInput) { return this._fauxInput }

    this._fauxInput = document.createElement('div')
    this._fauxInput.classList.add('faux-email-input')
    this._fauxInput.appendChild(this.innerDiv)
    this._fauxInput.dataset.action = 'click->email-input#focusInner keydown->email-input#handleKeydown'
    this._fauxInput.dataset.target = 'email-input.fauxInput'

    return this._fauxInput
  }

  focusInner(event) {
    this.innerDiv.focus()
  }

  get innerDiv() {
    if(this._innerDiv) { return this._innerDiv }

    this._innerDiv = document.createElement('div')
    this._innerDiv.setAttribute('contenteditable', 'true')
    this._innerDiv.setAttribute('autocapitalize', 'none')
    this.innerDiv.dataset.action = `animationend->email-input#removeInvalidAnimation
                                    focus->email-input#handleFocus
                                    blur->email-input#handleBlur
                                    paste->email-input#handlePaste`

    return this._innerDiv
  }

  handleFocus() {
    this.fauxInput.classList.add('focus')
  }

  handleBlur(event) {
    this.addEmailToList()
    this.fauxInput.classList.remove('focus')
  }

  handlePaste(event) {
    event.preventDefault()
    let text = event.clipboardData.getData("text/plain")
    document.execCommand("insertHTML", false, text);
  }

  removeInvalidAnimation() {
    this.innerDiv.classList.remove('invalid')
  }

  get originalInput() {
    return this.targets.find('originalInput')
  }

  addNewOption(value) {
    let newOption = document.createElement('option')
    newOption.setAttribute('selected', 'selected')
    newOption.value = value
    newOption.text = value

    this.originalInput.appendChild(newOption)
  }
}

