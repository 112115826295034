import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['presetPanel', 'checkbox']

  connect() {
    this.updatePresetVisibility()
  }

  updatePresetVisibility(){
    if(this.checkboxTarget.checked){
      this.showPresetPanel()
    } else {
      this.hidePresetPanel()
    }
  }

  showPresetPanel(){
    this.presetPanelTarget.classList.remove('hidden')
  }

  hidePresetPanel(){
    this.presetPanelTarget.classList.add('hidden')
  }

  checkAll(event){
    event.preventDefault()
    const cbs = this.presetPanelTarget.querySelectorAll('input[type="checkbox"]')
    cbs.forEach(function(cb){
      cb.checked = true
    })
  }

  uncheckAll(){
    event.preventDefault()
    const cbs = this.presetPanelTarget.querySelectorAll('input[type="checkbox"]')
    cbs.forEach(function(cb){
      cb.checked = false
    })
  }
}
