import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  static targets = ["profileTypeDd"]

  profileChange() {
    axios.get('/profile_types/' + this.profileTypeId + '/custom_fields')
      .then(function(res) {
        document.querySelector('.search_filter_custom_field_value').outerHTML = res.data
      })
      .catch(function(e){
        console.log(e)
      })
  }

  pipelineChange(event) {
    let pipelineId = event.target.value

    axios.get('/pipelines/' + pipelineId + '/pipeline_stages')
      .then(function(res) {
        document.querySelector('.search_filter_pipeline_stage_ids').outerHTML = res.data
      })
      .catch(function(e){
        console.log(e)
      })
  }

  customFieldChange(event) {
    let fieldKey = event.target.value

    axios.get('/profile_types/' + this.profileTypeId + '/custom_fields?field_key=' + fieldKey)
      .then(function(res) {
        document.querySelector('.search_filter_custom_field_value').outerHTML = res.data
      })
      .catch(function(e){
        console.log(e)
      })
  }

  get profileTypeId() {
    return this.profileTypeDdTarget.value
  }
}
