import { Controller } from 'stimulus'

export default class extends Controller {
  changeOffice(event) {
    console.log('here')
    let ddVal = this.element.value || 'emg'
    let body = document.querySelector('body')
    let host = window.location.host
    let newHost = host.replace(/^\w+\./, ddVal+'.');
    let newLocation = window.location.protocol + '//' + newHost + window.location.pathname

    this.element.setAttribute('disabled', 'disabled')
    this.element.style.cursor = 'wait'
    body.style.cursor = 'wait'

    window.location.href = newLocation
  }
}

