import { Controller } from 'stimulus'
import FormattedNumberField from '../../lib/formatted-number-field'
import NumberFormatter from '../../lib/number-formatter'

export default class extends Controller {
  connect() {
    let numberFormatter = new NumberFormatter('percent')
    this.formattedNumberField = new FormattedNumberField(this.element, numberFormatter)
    this.formattedNumberField.setup.bind(this.formattedNumberField)()
  }

  disconnect() {
    this.formattedNumberField.teardown.bind(this.formattedNumberField)()
  }
}
