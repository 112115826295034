import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['info', 'checkbox']

  connect() {
    this.handleProfileTypeChange()
  }

  handleProfileTypeChange() {
  }
}
