import { Controller } from "stimulus"
import Noty from '../../lib/noty'

export default class extends Controller {
  static targets = ['alert']
  connect() {
    this.alertTargets.forEach(this.generateAlert.bind(this))
  }

  generateAlert(element) {
    new Noty({type: this.type(element), text: this.text(element)}).show()
    element.remove()
  }

  type(element) {
    return element.getAttribute('data-alert-type')
  }

  text(element) {
    return element.getAttribute('data-alert-text')
  }
}

