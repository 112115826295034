import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    let thsForWidth = this.element.querySelectorAll('th');


    this.sortable.sortable({
      handle: '.handle',
      items: 'tr',
      placeholder: 'sortable-placeholder',
      axis: 'y',

      start: function(event, ui){
        let element = ui.item.get(0)
        let tds = Array.from(element.getElementsByTagName('td'))
        let placeholder = document.querySelector('.sortable-placeholder')

        placeholder.style.height = `${element.offsetHeight}px`

        tds.forEach(function(td, index){
          td.width = thsForWidth[index].offsetWidth;
        })
      },

      update: function(event, ui){
        var data = $(this).sortable("serialize");
        $.ajax({
          url: "/manage_pricing/proposal_faq_positions",
          type: "POST",
          data: {proposal_faq_positions: data},
          success: function(resp){ }
        });
      }
    });
  }

  get sortable() {
    return $(this.targets.find('sortContainer'))
  }

  disconnect() {
    this.sortable.sortable('destroy')
  }
}

