import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  change(){
    const bankId = this.element.value
    const emptyOption = '<option value>Not Set</option>'

    this.branchFieldId = this.data.get("branchFieldId")
    this.branchField.innerHTML = emptyOption

    axios.get('/banks/' + bankId + '/branches.json')
      .then((response) => {
        let html = emptyOption
        response.data.forEach(function(branch){
          html += `<option value="${branch.id}">${branch.name}</option>`
        })
        this.branchField.innerHTML = html
      })
      .catch(function(e){
        console.log(e)
        console.log('failed to retrieve bank branches')
      })
  }

  get branchField() {
    return document.getElementById(this.branchFieldId)
  }
}

