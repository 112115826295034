import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    $(this.element).tablesorter({
      sortInitialOrder: 'desc',
    })
  }

  disconnect() {
    $(this.element).trigger('destroy')
  }
}

