import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['info', 'checkbox']

  connect() {
    this.handleCheckbox()
  }

  handleCheckbox() {
    if(this.checkboxTarget.checked){
      this.showInfo()
    }else{
      this.hideInfo()
    }
  }

  hideInfo(){
    this.infoTarget.classList.add('hidden')
  }

  showInfo(){
    this.infoTarget.classList.remove('hidden')
  }
}
