import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.determineVisibility([])
    this.observer = new MutationObserver(this.determineVisibility.bind(this))
    this.observer.observe(this.observedContainerElement, { childList: true, attributes: true, subtree: true })
  }

  disconnect() {
    this.observer.disconnect()
  }

  determineVisibility(mutationsList) {
    let visibleChildren = 0

    for (var i = 0; i < this.observedContainerElement.children.length; i++) {
      if(this.observedContainerElement.children[i].style.display !== 'none') {
        visibleChildren ++
      }
    }

    if(visibleChildren > 0) {
      this.element.style.display = null
    } else {
      this.element.style.display = 'none'
    }
  }

  get observedContainerElement() {
    return this.targets.find('observedContainer')
  }
}
