import { Controller } from "stimulus"

export default class extends Controller {
  remove(event) {
    event.preventDefault()
    this.destroyInputElement.value = "1"
    this.element.style.display = 'none'
  }

  get destroyInputElement() {
    return this.targets.find('destroyInput')
  }
}

