import { Controller } from 'stimulus'
import submitEvent from "../../../lib/submit-event"

export default class extends Controller {
  static targets = ['form', 'hiddenInput']

  saveAndReturn(){
    this.hiddenInputTarget.value = 'saveAndReturn'
    this.formTarget.dispatchEvent(submitEvent)
  }

  saveAndSend(){
    this.hiddenInputTarget.value = 'saveAndSend'
    this.formTarget.dispatchEvent(submitEvent)
  }
}
