import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    let thsForWidth = this.element.querySelectorAll('th');
    let pipelineId = this.data.get('pipelineId')

    this.sortable.sortable({
      handle: '.handle',
      items: 'tr',
      placeholder: 'sortable-placeholder',
      axis: 'y',

      start: function(event, ui){
        let element = ui.item.get(0)
        let tds = Array.from(element.getElementsByTagName('td'))
        let placeholder = document.querySelector('.sortable-placeholder')

        placeholder.style.height = `${element.offsetHeight}px`

        tds.forEach(function(td, index){
          td.width = thsForWidth[index].offsetWidth;
        })
      },

      update: function(event, ui){
        var data = $(this).sortable("serialize");
        $.ajax({
          url: '/setup_system/pipelines/'+pipelineId+'/pipeline_stage_positions',
          type: "POST",
          data: {pipeline_stage_positions: data},
          success: function(resp){ }
        });
      }
    });
  }

  get sortable() {
    return $(this.targets.find('sortContainer'))
  }

  disconnect() {
    this.sortable.sortable('destroy')
  }
}
