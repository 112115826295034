import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    window.addEventListener("hashchange", this.navigate.bind(this))
    this.navigate()
  }

  disconnect() {
    window.removeEventListener("hashchange", this.navigate.bind(this))
  }

  navigate() {
    let id = this.id
    this.clearState()
    this.showNav(id)
    this.showSection(id)
    window.scroll(0,0)
  }

  get id(){
    return (location.hash || '#message-content').replace('#', '')
  }

  click(event) {
    let target = event.currentTarget
    event.preventDefault()
    location.hash = target.getAttribute('href')
  }

  showSection(divId) {
    document.getElementById(divId).classList.add('active')
  }

  showNav(id) {
    document.querySelector(`[href="#${id}"]`).classList.add('active')
  }

  clearState() {
    this.element.querySelectorAll('.active').forEach(function(element){
      element.classList.remove('active')
    })
  }
}
