import { Controller } from "stimulus"
import Tooltip from "tooltip.js"

export default class extends Controller {
  connect() {
    let placement = this.data.get('placement') || 'top'
    let trigger = this.data.get('trigger') || 'hover'

    this.title = this.data.get('title') || this.element.getAttribute('title')
    this.data.set('title', this.title)
    this.element.removeAttribute('title')

    this.tip = new Tooltip(this.element, {
      trigger: trigger,
      placement: placement,
      title: this.title
    })
  }

  disconnect() {
    this.tip.dispose()
  }

  click(){
    this.tip.hide()
  }
}
