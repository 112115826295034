import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.submitForm)
  }

  disconnect() {
    this.element.removeEventListener("click", this.submitForm)
  }

  submitForm(event) {
    event.preventDefault()
    var form = document.getElementById('statement-breakdown-form');
    form.dispatchEvent(new Event('submit', {bubbles: true, cancelable: true}));
  }
}


