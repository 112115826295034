import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let bs = this.element.querySelector('.bootstrap-select')
    let inputGroup = this.element.querySelector('.input-group')
    this.selectTarget = this.element.querySelector('select')

    if(bs && inputGroup) {
      inputGroup.insertBefore(this.selectTarget, bs)
      bs.remove()
    } else if(bs) {
      this.element.appendChild(this.selectTarget)
      bs.remove()
    }

    $(this.selectTarget).selectpicker({ iconBase: 'fa', tickIcon: 'fa-check', noneSelectedText: 'select' })
  }

  disconnect() {
    $(this.selectTarget).selectpicker('destroy')
  }
}
