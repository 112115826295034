import { Controller } from "stimulus"
import submitEvent from "../../lib/submit-event"

export default class extends Controller {
  static targets = ["form"]

  submitForm() {
    this.formTarget.dispatchEvent(submitEvent)
  }
}
